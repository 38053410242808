<style scoped lang="less">
  .card {
    background-color: #FFF;
    border-radius: 5px;
    .header {
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, .85);
      &::before {
        content: '';
        height: 20px;
        width: 3px;
        display: inline-block;
        margin-right: 20px;
      }
    }
    &.flex {
      display: flex;
      flex-direction: column;
      .content {
        flex: 1;
        height: 0;
        position: relative;
      }
    }
    &.hborder {
      .header {
        border-bottom: 1px solid #E9E9E9;
      }
    }
  }
  .blue-theme, .dark-theme {
    .title {
      &::before {
        background-color: #6B75CA;
      }
    }
  }
  .pink-theme {
    .title {
      &::before {
        background-color: #F4628F;
      }
    }
  }
  .yellow-theme {
    .title {
      &::before {
        background-color: #6C573C;
      }
    }
  }
</style>

<template>
  <div class="card" :class="{ flex: flex, hborder: hborder }">
    <div class="header">
      <div class="title"><slot name="title"></slot></div>
      <slot name="action"></slot>
    </div>
    <div class="content"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    flex: { type: Boolean, default: false },
    hborder: { type: Boolean, default: false }
  }
}
</script>
