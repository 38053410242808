<style scoped lang="less">
  .r-imgs {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 184px;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    .img {
      width: 100%;
      height: 105px;
      position: relative;
    }
    img {
      cursor: pointer;
      width: 100%;
      display: block;
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
    }
  }
  .wrap {
    padding: 15px;
    box-sizing: border-box;
    position: relative;
  }
  .action {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
    .menu {
      cursor: pointer;
      font-size: 12px;
      span + span:before {
        cursor: default;
        content: '|';
        margin: 0 5px;
      }
    }
  }
  .checkbox {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 30px;
      border: 1px solid #EEE;
      border-radius: 5px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(2px);
      &::after {
        content: '';
        height: 18px;
        width: 10px;
        border: 6px solid transparent;
        border-top: 0;
        border-left: 0;
        display: inline-block;
        transition: all .3s;
        transform: rotate(45deg);
      }
    }
    &.checked {
      background-color: rgba(0, 0, 0, 0.1);
      i {
        &::after {
          border-color: #F4628F;
        }
      }
    }
  }
  .blue-theme, .dark-theme {
    .r-imgs {
      .item {
        border: 1px solid #6F76B7;
        border-radius: 4px;
        background: #F0F1FA;
      }
      .action {
        .menu, .name {
          color: #6F76B7;
        }
      }
    }
  }
  .pink-theme {
    .r-imgs {
      .item {
        border: 1px solid #F4628F;
        border-radius: 4px;
        background: rgba(244, 98, 143, .1);
      }
      .action {
        .menu, .name {
          color: #F4628F;
        }
      }
    }
  }
  .yellow-theme {
    .r-imgs {
      .item {
        border: 1px solid #6C573C;
        border-radius: 4px;
        background: rgba(108, 87, 60, .1);
      }
      .action {
        .menu, .name {
          color: #6C573C;
        }
      }
    }
  }
</style>

<template>
  <div class="r-imgs" v-loadingx="loading.load">
    <div class="wrap" v-for="(img, index) in covers" :key="index">
      <div class="item">
        <div class="img">
          <img v-lazy="Array.isArray(img.src) ? img.src[0] : img.src" @click="atPreview(index)" />
          <div class="checkbox" @click="atCheckbox(index)" v-if="checkbox" :class="{checked: checkedList.includes(index)}">
            <i></i>
          </div>
        </div>
        <div class="action">
          <div class="name">{{img.label || name}}</div>
          <div class="menu">
            <span @click="download(index, img.label)">下载</span>
            <span @click="atPreview(index)">预览</span>
          </div>
        </div>
      </div>
    </div>
    <ImgPreview v-if="status.preview" @close="status.preview = false" :show="status.preview" :index="index" :imgs="sources" is-source/>
  </div>
</template>

<script>
import ImgPreview from '@/components/base/file/preview/img'
import { isPdf } from '@/components/base/file/lib'

export default {
  components: { ImgPreview },
  props: {
    checkbox: { type: Boolean, default: false },
    checked: { type: Array, default: () => [] },
    name: { type: String, default: '图片' },
    covers: { type: Array, default: () => [] },
    previews: { type: [Array, Function], default: null }
  },
  data () {
    return {
      checkedList: this.checked,
      previewList: [],
      index: 0,
      status: {
        preview: false
      },
      loading: {
        load: false
      }
    }
  },
  watch: {
    covers () {
      this.previewList = []
    },
    previews () {
      this.previewList = []
    },
    checked (v) {
      this.checkedList = v
    }
  },
  computed: {
    sources () {
      return this.previewList.map(v => v.src).flat()
    }
  },
  methods: {
    atCheckbox (index) {
      if (this.checkedList.includes(index)) {
        this.checkedList.splice(this.checkedList.findIndex(v => v === index), 1)
      } else {
        this.checkedList.push(index)
      }
      this.$emit('checked', this.checkedList)
    },
    async download (index, label) {
      await this.loadPreview()
      const blob = this.previewList[index].src
      const ext = isPdf(this.previewList[index].name) ? 'png' : this.previewList[index].name.split('.').pop()
      if (Array.isArray(blob)) {
        blob.forEach((src, i) => {
          let a = document.createElement('a')
          a.href = src.replace('&mode=preview', '&mode=download')
          if (label) {
            a.download = label + '-' + i
          } else if (this.name) {
            a.download = this.name + '-' + i
          } else {
            a.download = new Date().getTime() + '-' + i
          }
          a.download += '.' + ext
          a.click()
        })
      } else {
        let a = document.createElement('a')
        a.href = blob.replace('&mode=preview', '&mode=download')
        a.download = label || this.name || new Date().getTime()
        a.download += '.' + ext
        a.click()
      }
    },
    async loadPreview () {
      if (typeof this.previews === 'function') {
        this.previewList = this.covers
        const list = this.previews()
        if (list instanceof Promise) {
          this.previewList = await list
        } else {
          this.previewList = list
        }
      } else if (Array.isArray(this.previews)) {
        this.previewList = this.previews
      } else {
        this.previewList = this.covers
      }
    },
    // async atPreview (index) {
    //   this.loading.load = true
    //   if (!this.previewList.length) {
    //     await this.loadPreview()
    //   }
    //   this.index = index
    //   this.status.preview = true
    //   this.loading.load = false
    // },
    async atPreview (index) {
      this.index = index
      this.status.preview = true
      this.loadPreview()
    }
  },
  mounted () {
  }
}
</script>
